function getHeaders(data) {
    if (data instanceof FormData) {
        return {
            'X-CSRF-Token': document.querySelector('[name="csrf-token"]').content
        }
    }
    return {
        'accept': 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('[name="csrf-token"]').content
    }
}

async function getResponse(url, method="GET", data=undefined) {
    if (data && !(data instanceof FormData)) {
        data = JSON.stringify(data);
    }

    return await fetch(url, {
        method: method,
        headers: getHeaders(data),
        body: data
    });
}

export default {
    get: async (url) => await getResponse(url),
    post: async (url, data) => await getResponse(url, 'POST', data),
    patch: async (url, data) => await getResponse(url, 'PATCH', data),
    put: async (url, data) => await getResponse(url, 'PUT', data),
    delete: async (url) => await getResponse(url, 'DELETE')
}
